import React from "react";
import { ContactForm } from "@/components/contact-form";
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo";
import { Layout } from "@/components/layout";
import { ApplicationForm } from "@/components/application-form";

const BusinessAnalystPage = () => {
  return (
    <>
      <GatsbySeo
        title="Business Development - Careers | Tectra Technologies"
        canonical="https://www.tectratechnologies.com/careers/business-development/"
        openGraph={{
          locale: "en_US",
          type: "article",
          url: "https://www.tectratechnologies.com/careers/business-development/",
          title: "Business Development - Careers | Tectra Technologies",
        }}
        twitter={{
          handle: "@handle",
          site: "@TectraL",
          cardType: "summary_large_image",
        }}
      />
      <JsonLd
        json={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          "@graph": [
            {
              "@type": "WebSite",
              "@id": "https://www.tectratechnologies.com/#website",
              url: "https://www.tectratechnologies.com/",
              name: "Tectra Technologies",
              description:
                "Web &amp; Mobile App, Cloud, UI/UX, Devops, Tax Services, Digital Marketing",
              potentialAction: [
                {
                  "@type": "SearchAction",
                  target: {
                    "@type": "EntryPoint",
                    urlTemplate:
                      "https://www.tectratechnologies.com/?s={search_term_string}",
                  },
                  "query-input": "required name=search_term_string",
                },
              ],
              inLanguage: "en-US",
            },
          ],
        }}
      />
      <Layout>
        <div className="bg-white mt-28 lg:mt-32">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-left text-3xl font-poppins font-normal captalize tracking-tight">
              Business Development
            </h2>
            <p className="text-lg font-poppins font-thin text-black">
              Chennai, IN - Full Time
            </p>

            <div className="mt-6">
              <ApplicationForm job_type="Business Development" />
            </div>
          </div>
        </div>

        <div className="bg-white mt-4">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <div className="grid lg:grid-cols-12">
              <div className="lg:col-span-12">
                <h5 className="mb-4 mt-2 font-medium underline text-lg">
                  Requirements:-
                </h5>

                <ul className="list-disc text-black ml-8">
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Any graduate
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    1+ years in IT industry must
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Experience in full sales cycle including deal closing
                    Demonstrated sales success
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Strong negotiation skills
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Strong communication and presentation skills
                  </li>
                </ul>

                <h5 className="mb-4 mt-6 font-medium underline text-lg">
                  Job Responsibilities:-
                </h5>

                <ul className="list-disc mb-0 ml-8">
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Build relationships with prospective clients
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Maintain consistent contact with existing clients
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Manage sales pipeline
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Analyze market and establish competitive advantages
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Track metrics to ensure targets are hit
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BusinessAnalystPage;
